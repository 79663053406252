import React from 'react'

const NotFoundPage = () => (
	<div
		style={{
			width: '100%',
			height: '100%',
			position: 'fixed',
			display: 'flex',
			justifyContent: 'space-around',
			alignItems: 'middle'
		}}
	>
		<h1>I CANNOT NOT FOUND</h1>
	</div>
)

export default NotFoundPage
